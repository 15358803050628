<template>
  <v-list-item
    @click.stop="$emit('editclick')"
    :class="{ 'account-inactive': !account.active }"
  >
    <v-list-item-avatar
      @click.stop=""
      @mouseover.stop="hover = true"
      @mouseleave.stop="hover = false"
    >
      <v-checkbox
        v-show="showCheckbox"
        :input-value="selected"
        @change="toggle"
        hide-details
        class="checkbox-solo"
      />
      <text-icon colorize v-show="!showCheckbox">{{ account.name }}</text-icon>
    </v-list-item-avatar>
    <v-row style="width: 100%" align="center">
      <v-col cols="12" md="3">{{ account.name }}</v-col>
      <v-col md="3" class="hidden-sm-and-down text-truncate">{{
        account.email
      }}</v-col>
      <v-col md="3" class="hidden-sm-and-down text-truncate">
        <v-chip
          small
          label
          v-for="role in account.roles"
          :key="role"
          color="teal lighten-4"
          class="mr-1"
        >
          {{ role | mapRole }}
        </v-chip>
      </v-col>
      <v-col md="3" class="hidden-sm-and-down text-truncate">
        <v-chip
          small
          label
          v-for="org in account.organisations"
          :key="org"
          color="blue lighten-3"
          class="mr-1"
        >
          {{ org | mapOrg }}
        </v-chip>
      </v-col>
    </v-row>
    <v-list-item-action class="list-tile-action-multiple">
      <v-btn icon @click.stop="$emit('editclick')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <div @click.stop="">
        <v-menu left offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item @click="$emit('delete')">Löschen</v-list-item>
            <v-list-item v-if="account.active" @click="toggleActiveInactive"
              >Login sperren</v-list-item
            >
            <v-list-item v-else @click="toggleActiveInactive"
              >Login aktivieren</v-list-item
            >
          </v-list>
        </v-menu>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AccountTile',
  components: {},
  props: {
    account: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    showCheckbox() {
      return this.hover || this.selected
    },
  },
  methods: {
    toggle(payload) {
      this.$emit('selected', payload)
    },
    toggleActiveInactive() {
      const account = cloneDeep(this.account)
      account.active = !account.active
      this.$emit('save', account)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox-solo {
  margin-top: 0;
  padding-top: 0;
  margin-left: 8px;
}

.account-inactive {
  background-color: rgba(200, 0, 0, 0.05);
}
</style>
