import { defineStore } from 'pinia'
import Vue from 'vue'
import accountService from '@/services/account'

export const useAccountStore = defineStore('account', {
  state: () => ({
    accounts: {},
  }),
  actions: {
    _updateAccount(account) {
      Vue.set(this.accounts, account.id, account)
    },
    fetchAccounts() {
      return accountService.list()
        .then((accounts) => {
          this.accounts = {}
          for(const a of accounts) {
            Vue.set(this.accounts, a.id, a)
          }
          return accounts
        })
    },
    getAccount(id) {
      return accountService.get(id)
        .then((account) => {
          this._updateAccount(account)
          return account
        })
    },
    updateAccount(account) {
      return accountService.update(account)
        .then(account => {
          this._updateAccount(account)
          return account
        })
    },
    createAccount(account) {
      return accountService.create(account)
        .then(account => {
          this._updateAccount(account)
          return account
        })
    },
    deleteAccount(account) {
      return accountService.remove(account)
        .then(account => {
          Vue.delete(this.accounts, account.id)
          return account
        })
    },
  },
  getters: {
    list: (state) => {
      return Object.values(state.accounts).sort((a, b) => (a.name || '').localeCompare(b.name))
    },
  },
})
